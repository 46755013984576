import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import firebase from 'firebase/app';
import 'firebase/analytics';
import App from './App';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
    apiKey: "AIzaSyAykbdJsJ-Hg1ZnLGo22zzeLLJ-p8s8ro4",
    authDomain: "rc-mp3-downloader.firebaseapp.com",
    projectId: "rc-mp3-downloader",
    storageBucket: "rc-mp3-downloader.appspot.com",
    messagingSenderId: "913987603106",
    appId: "1:913987603106:web:11c7a280f8c171adc425fb",
    measurementId: "G-PHQTX9QV2C"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
