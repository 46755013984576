import * as React from 'react';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import './App.css';
import youtubeLogo from './svg/youtube-logo.svg';
import downloadLogo from './svg/download.svg'

function App() {

    const {register, handleSubmit} = useForm();
    const [youtubeId, setYoutubeId] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const onSubmit = data => {
        let urlParams;
        try {
            urlParams = new URLSearchParams(new URL(data.url).search);
            if (urlParams.has("v")) {
                setYoutubeId(urlParams.get("v"));
                setErrorMessage(undefined);
            } else {
                setYoutubeId(undefined);
                setErrorMessage("Invalid URL");
            }
        } catch(err) {
            setYoutubeId(undefined);
            setErrorMessage("Invalid URL");
        }
    };

    return (
        <div className="app">
            <div className="main">
                <img src={youtubeLogo} className="main-logo" alt="youtube"/>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <div>
                        <input name="url" ref={register} placeholder="https://www.youtube.com/watch?v=XXXXXXX"
                               className="url"/>
                        {/*<input type="submit" value="Download" className="download"/>*/}
                        {/*<img src={downloadLogo} className="download-button" alt="download"/>*/}
                        <input type="image" src={downloadLogo} className="download-button" alt="Submit" />
                    </div>
                </form>

                {youtubeId && <iframe src={`https://www.yt-download.org/api/button/mp3/${youtubeId}`}
                                      title="youtube-to-mp3"
                                      width="100%"
                                      height="100px"
                                      scrolling="no"
                                      style={{border: 'none'}}
                                      className="iframe">
                </iframe>}

                {errorMessage && <div className="error">
                    {errorMessage}
                </div>}
            </div>

        </div>
    );
}

export default App;
